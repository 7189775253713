.menu-list-misstion {
  * {
    box-sizing: border-box !important;
  }
  background-color: white !important;
  padding: 4px !important;
  border-radius: 4px;
  box-sizing: border-box !important;
  border: 1px solid rgba(211, 216, 221, 1);
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.24);
  .menu-option-label {
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: rgba(17, 51, 94, 1);
    font-weight: 400;
  }
}