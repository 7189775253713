.input-task-title {
  font-family: "Avenir";
  border: 1px solid #D9DFE2 !important;
  border-radius: 56px;
  padding: 16px 22px !important;
  width: 100%;
  font-size: 14px;
  color: rgba(132, 135, 138, 1) !important;
  outline: none;
  &::placeholder {
    color: rgba($color: #003361, $alpha: 0.2);
  }
}

.input-disable-pointer {
  pointer-events: none;
}

.input-edit-title {
  box-sizing: border-box;
  border-radius: 7px;
  padding: 3px 6px !important;
  font-size: 14px;
  font-weight: 800;
  line-height: 19.12px;
  color: black !important;
  background-color: transparent;
  &:disabled {
    border: none !important;
  }
}

.input-add-sub-task {
  font-family: "Avenir";
  border: 1px solid #D9DFE2 !important;
  border-radius: 10px;
  padding: 8.5px 12px !important;
  width: 100%;
  font-size: 14px;
  color: rgba(132, 135, 138, 1) !important;
  outline: none;
  &::placeholder {
    color: rgba($color: #003361, $alpha: 0.2);
  }
}