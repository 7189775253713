@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/styles/input.scss';
@import './assets/styles/dialog.scss';
@import './assets/styles/button.scss';
@import './assets/styles/icon.scss';
@import './assets/styles/menu.scss';

@font-face {
  font-family: "Rockness";
  src: local("Rockness"), url(assets/fonts/Rockness.otf) format("opentype");
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(assets/fonts/Avenir.otf) format("opentype");
}

body {
  margin: 0;
  font-family: "Avenir";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ecf4f8;
}

code {
  font-family: "Avenir";
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
  background-color: rgba($color: #003361, $alpha: 0.1);
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba($color: #003361, $alpha: 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba($color: #003361, $alpha: 0.5);
}

.MuiGrid-spacing-xs-2,
.MuiGrid-spacing-xs-4 {
  width: 100% !important;
  margin: 0px !important;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1200px !important;
  }
}

@media (min-width: 960px) {
  #SideNavigation {
    flex-grow: 0 !important;
    max-width: 10.666667% !important;
    flex-basis: 10.666667% !important;
  }

  #MainContent {
    flex-grow: 0 !important;
    max-width: 89.333333% !important;
    flex-basis: 89.333333% !important;
  }
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 150px !important;
  }
}

input:not(.select-time-reminder):-webkit-outer-spin-button,
input:not(.select-time-reminder)::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-color {
  color: #7ac8c3 !important;
}

.phone-icon {
  width: 28px !important;
  height: 30px !important;
}

.vertical-menu-icon {
  transform: rotate(90deg);
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.error {
  font-size: 12px !important;
  font-family: "Avenir" !important;
  color: #dc3545 !important;
  text-align: left !important;
  margin: 0px !important;
  padding-left: 20px !important;
  margin-top: -15px !important;
  &-edit-title {
    margin-top: 0px !important;
    padding-left: 0 !important;
    color: white !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    padding-top: 4px !important;
  }
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.button-raised {
  font-size: 14px;
  font-family: "Avenir";
  color: #fff !important;
  background: linear-gradient(90deg, #88C3C0 0%, #507D91 48%, #13355F 100%);
  box-shadow: 0px 11px 16px #7ac8c31a;
  border-radius: 100px;
  display: block;
  margin: 5px 10px 0 10px;
  border: none;
  outline: none;
  width: -webkit-fill-available;
  padding: 12px;
  cursor: pointer;
  letter-spacing: 2px;
  position: relative;

  &:hover {
    text-decoration: none !important;
  }
}

.button-stroke {
  font-size: 14px;
  font-family: "Avenir";
  color: #58595b !important;
  background-color: transparent;
  border: 1px solid #58595b;
  box-shadow: 0px 11px 16px #7ac8c31a;
  border-radius: 100px;
  display: block;
  margin: 0px 10px !important;
  outline: none;
  width: -webkit-fill-available;
  padding: 12px;
  cursor: pointer;
  letter-spacing: 2px;
  margin-top: 5px !important;
  position: relative;

  &:hover {
    text-decoration: none !important;
  }
}

.button-disabled {
  background: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  cursor: inherit;
}

@media (max-width: 600px) {
  .button-stroke,
  .button-raised {
    font-size: 12px;
    padding: 10px;
  }
}

.disabled {
  opacity: 0.3;
  cursor: context-menu !important;
}

.text-strike {
  text-decoration: line-through !important;
  opacity: 0.4 !important;
}

.menu-btn {
  margin: 0px 20px !important;
  width: 55px;
  height: 55px;
  background-color: rgba($color: #003361, $alpha: 0.1) !important;

  svg {
    fill: #003361;
  }
}

@media (max-width: 600px) {
  .menu-btn {
    margin: 0px 15px !important;
    width: 45px;
    height: 45px;
  }
}

// *Loader
.resend {
  animation: spinner 0.8s linear infinite;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 15%;
  width: 17px;
  height: 17px;
  margin-top: -10px;
  margin-left: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #b6b6b6;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 959px) {
  .text-sm-center {
    text-align: center !important;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }
}

@media (max-width: 600px) {
  .text-xs-center {
    text-align: center !important;
  }
}

// *Side Navigation
.navigation {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  height: 100vh;
  text-align: center;

  .logo {
    width: 100px;
    height: 90px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;

    li,
    a {
      font-size: 14px;
      font-family: "Avenir";
      color: #7ac8c3;
      background-color: #fff;
      width: 100%;
      height: 100px;
      display: table;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      border-radius: 20px;
      margin: 15px 0px;
      transition: 0.3s ease-in-out;

      &:hover {
        box-shadow: 0px 3px 28px #00000014;
        transition: 0.3s ease-in-out;
      }

      div {
        display: table-cell;
        vertical-align: middle;
      }

      svg {
        width: 25px;
        height: 25px;
        display: block;
        margin: 5px auto;
        fill: #7ac8c3;
      }
    }

    @media (max-width: 959px) {
      li,
      a {
        width: 120px;
      }
    }

    .active {
      box-shadow: 0px 3px 28px #00000014;
      transition: 0.3s ease-in-out;
    }
  }
}

@media (max-width: 959px) {
  .navigation {
    height: 100%;
  }

  .MuiDrawer-paper {
    width: 200px;
  }
}

// *Main Content
#MainContent {
  background-color: #ecf4f8;
  padding: 10px 20px;

  // *Header
  .header {
    padding: 20px 0px;
    border-bottom: 1px solid rgba(42, 42, 42, 0.1);

    h2 {
      font-size: 32px;
      font-family: "Avenir";
      font-weight: 900;
      color: #003361;
      text-transform: capitalize;
    }

    h4 {
      font-size: 18px;
      font-family: "Avenir";
      font-weight: 900;
      color: #003361;
      text-transform: capitalize;
      display: inline-block;
    }

    .logo {
      width: 120px;
      height: 100px;
    }

    .quote {
      position: relative;

      &:hover {
        p {
          opacity: 0;
          transition: 0.3s ease-in-out;
        }
        .sponsored {
          opacity: 1;
          transition: 0.3s ease-in-out;
        }
      }

      p {
        font-size: 14px;
        font-family: "Avenir";
        color: #58595b;
        padding: 0px;
        cursor: inherit;
        transition: 0.3s ease-in-out;
      }

      .sponsored {
        position: absolute;
        left: 0px;
        top: 0px;
        padding: 2px 12px;
        background-color: rgba($color: #7ac8c3, $alpha: 0.4);
        border-radius: 20px;
        width: 100%;
        opacity: 0;
        transition: 0.3s ease-in-out;

        span {
          font-size: 12px;
          font-family: "Avenir";
          color: #7ac8c3;
          margin-right: 70px;
        }

        @media (max-width: 600px) {
          span {
            margin-right: 30px;
          }
        }

        .link {
          font-size: 12px;
          font-family: "Avenir";
          color: #58595b;
          cursor: pointer;
        }
      }

      @media (max-width: 600px) {
        p {
          font-size: 12px;
        }
        .sponsored {
          padding-top: 4px;
          padding-bottom: 5px;
        }
      }

      @media (max-width: 959px) {
        .sponsored {
          text-align: left;
          padding-right: 0px;
          padding-top: 6px;
          padding-bottom: 7px;
        }
      }
    }

    .notification {
      margin: 0px 20px;
      width: 55px;
      height: 55px;
    }

    .notification {
      background-color: rgba($color: #003361, $alpha: 0.1);

      svg {
        fill: #003361;
      }
    }

    .dropdown {
      width: 376px;
      margin-top: 10px !important;
      left: -2% !important;
      z-index: 100;

      .MuiPaper-root {
        box-shadow: 0px 3px 99px #0000001a;
        border-radius: 29px;
        overflow: hidden;
        .MuiListItem-button:hover {
          background-color: transparent !important;
        }
      }

      h1 {
        font-size: 34px;
        font-family: "Rockness";
        color: #6ba570;
        text-align: center;
        margin: 8px 0px;
      }

      .notify-wrapper {
        max-height: 400px;
        overflow-y: auto;
        min-height: 400px;
        overflow-x: hidden !important;
        position: relative;

        .MuiButtonBase-root  {
          padding: 0 !important;
        }
        &-loading {
          display: grid;
          align-items: center;
          justify-items: center;
        }
        // .MuiCircularProgress-root {
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   transform: translate(-50%, -50%);
        // }
      }

      .MuiMenuItem-root {
        padding-top: 18px;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(42, 42, 42, 0.1);

        &:nth-last-child(1) {
          border-bottom: none;
        }

        .icon {
          background-color: rgba($color: #7ac8c3, $alpha: 0.2);
          text-align: center;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          position: relative;

          svg {
            fill: #7ac8c3 !important;
            width: 14px;
          }

          .unread {
            position: absolute;
            left: 2px;
            top: 2px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #e94444;
          }
        }

        .head {
          display: flex;
          justify-content: space-between;
          margin-left: 15px;
          margin-bottom: 8px;

          span {
            font-size: 12px;
            font-family: "Avenir";
            color: rgba($color: #58595b, $alpha: 0.5);
          }
        }

        p {
          font-size: 12px;
          font-family: "Avenir";
          color: #58595b;
          margin-left: 15px;
        }
      }

      @media (max-width: 959px) {
        h1 {
          font-size: 30px;
        }
      }

      @media (max-width: 600px) {
        h1 {
          font-size: 28px;
        }
      }
    }

    @media (max-width: 959px) {
      .dropdown {
        width: 270px;
      }
    }

    @media (max-width: 600px) {
      h2 {
        font-size: 22px;
      }

      h4 {
        font-size: 13px;
      }

      .logo {
        width: 100px;
        height: 80px;
      }

      .notification {
        margin: 0px 15px;
        width: 45px;
        height: 45px;
      }

      .dropdown {
        width: 250px;
      }
    }
  }

  @media (max-width: 959px) {
    .header {
      padding-top: 0px;
    }
  }

  // *Breadcrumbs
  .MuiBreadcrumbs-root {
    padding: 15px 0px;

    .MuiBreadcrumbs-li {
      p {
        font-size: 16px;
        font-family: "Avenir";
        font-weight: 900;
        color: #58595b;
        opacity: 0.7;

        &:hover {
          text-decoration: none !important;
        }
      }

      @media (max-width: 600px) {
        p {
          font-size: 14px;
        }
      }
    }
  }

  // *My Mission
  .mission {
    height: calc(100vh - 205px);
    overflow-y: auto;

    .wrapper {
      margin-right: 10px !important;
      flex-grow: 0 !important;
      max-width: 18.8% !important;
      flex-basis: 18.8% !important;
    }

    .task-box {
      width: 100%;
      height: auto;
      background-color: #fff;
      border: 1px solid;
      border-radius: 17px;
      margin-bottom: 12px !important;
      opacity: color 0.5;
      transition: 0.3s ease-in-out;
      overflow: hidden;
      * {
        box-sizing: border-box !important;
      }
      &:hover {
        box-shadow: 0px 3px 23px #0000000f;
        transition: 0.3s ease-in-out;
      }

      .header {
        border-radius: 11px;
        padding: 8px 8px;
        opacity: background-color 0.1;

        .MuiIconButton-root {
          background-color: rgba($color: #003361, $alpha: 0.1);
          width: 30px;
          height: 30px;
        }

        h5 {
          font-size: 14px;
          font-family: "Avenir";
          font-weight: 900;
          color: #58595b;
          padding-right: 5px;
          word-break: break-word;
        }

        h6 {
          font-size: 14px;
          font-family: "Avenir";
          font-weight: 900;
          color: #7ac8c3;
        }
      }

      .content {
        padding: 4px;
        padding-top: 6px;

        .task {
          display: flex;
          padding: 2px 0px;
          background-color: #fff;

          .checkbox {
            position: relative;

            input[type="checkbox"] {
              visibility: hidden;
            }

            input[type="checkbox"]:checked + label {
              background-color: #7ac8c3;
              border-color: #7ac8c3;
            }

            input[type="checkbox"]:checked + label:after {
              opacity: 1;
            }

            input {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 1px solid #7ac8c3;
              background-color: white;
              margin-right: 5px;
              vertical-align: middle;
              appearance: none;
              -webkit-appearance: none;
              outline: none;
              cursor: pointer;

              &:checked {
                background-color: #7ac8c3;
              }
            }

            label {
              border: 1px solid #7ac8c3;
              background-color: white;
              border-radius: 50%;
              cursor: pointer;
              width: 16px;
              height: 16px;
              position: absolute;
              left: 2px;
              top: 1px;

              &::after {
                content: "";
                width: 9px;
                height: 5px;
                position: absolute;
                left: 2px;
                top: 3px;
                border: 2px solid #fff;
                border-top: none;
                border-right: none;
                opacity: 0;
                transform: rotate(-45deg);
              }
            }
          }

          p {
            font-size: 14px;
            font-family: "Avenir";
            color: #58595b;
            opacity: 0.8;
            margin-bottom: 7px;
            width: 80%;
            word-break: break-word;
          }

          .deleted-task {
            background-color: rgba($color: #e94444, $alpha: 0.2);
            width: 26px;
            height: 26px;
            padding: 8px;

            &:hover {
              background-color: rgba($color: #e94444, $alpha: 0.2) !important;
            }

            svg {
              fill: #e94444 !important;

              path {
                fill: #e94444 !important;
              }
            }
          }

          .add-sub-task {
            width: 78%;
            border: none;
            outline: none;
            font-size: 14px;
            font-family: "Avenir";
            color: #58595b;
            opacity: 0.8;
          }
        }

        .add-subtask {
          display: flex;
          padding: 6px;
          border: solid 1px rgba(194, 205, 217, 1);
          border-radius: 10px;
          justify-content: center;
          .add-subtask-wrapper {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            column-gap: 10px;
          }
          &-title {
            color: rgba(149, 166, 186, 1);
            font-size: 14px;
            font-weight: 400;
          }
          &:hover, &:active {
            background-color: rgba(80, 125, 145, 0.11);
          }
        }
      }
    }

    .add-task {
      svg {
        vertical-align: text-top;
      }
    }

    @media (max-width: 959px) {
      .wrapper {
        flex-grow: 0 !important;
        max-width: 31% !important;
        flex-basis: 31% !important;
      }
    }

    @media (max-width: 600px) {
      .wrapper {
        flex-grow: 0 !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
        margin: 0px !important;
      }
    }
  }

  // *My Thought
  .thought {
    height: calc(100vh - 205px);
    overflow-y: auto;

    .thought-box {
      width: 100%;
      height: auto;
      background-color: #fff;
      border: 1px solid #7ac8c3;
      border-radius: 12px;
      margin-right: 10px !important;
      margin-bottom: 15px !important;
      transition: 0.3s ease-in-out;

      &:hover {
        box-shadow: 0px 3px 23px #0000000f;
        transition: 0.3s ease-in-out;
      }

      .header {
        background-color: rgba($color: #7ac8c3, $alpha: 0.1);
        border-radius: 11px;
        padding: 10px 8px;

        .MuiIconButton-root {
          background-color: rgba($color: #003361, $alpha: 0.1);
          width: 33px;
          height: 33px;
        }

        h5 {
          font-size: 15px;
          font-family: "Avenir";
          font-weight: 900;
          color: #58595b;
          word-break: break-word;
        }

        h6 {
          font-size: 14px;
          font-family: "Avenir";
          font-weight: 900;
          color: #7ac8c3;
        }
      }

      .content {
        padding: 10px 8px;

        p {
          font-size: 14px;
          font-family: "Avenir";
          color: #58595b;
          opacity: 0.8;
          margin-bottom: 7px;
          white-space: pre-line;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }

        span {
          font-size: 14px;
          font-family: "Avenir";
          font-weight: 700;
          color: rgba($color: #7ac8c3, $alpha: 0.7);
          cursor: pointer;
        }
      }
    }

    @media (min-width: 960px) {
      .thought-box {
        flex-grow: 0;
        max-width: 24%;
        flex-basis: 24%;
      }
    }

    @media (max-width: 959px) {
      .thought-box {
        flex-grow: 0;
        max-width: 48%;
        flex-basis: 48%;
      }
    }

    @media (max-width: 600px) {
      .thought-box {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        margin-right: 0px !important;
      }
    }
  }
  

  // *Write Thought
  .write-thought {
    .title {
      font-size: 26px;
      font-family: "Avenir";
      font-weight: 900;
      color: rgba($color: #003361, $alpha: 0.4);
      border: none;
      outline: none;
      background: transparent;
      width: 100%;
      margin: 10px 0px;

      &::placeholder {
        color: rgba($color: #003361, $alpha: 0.2);
      }
    }

    .description {
      font-size: 16px;
      font-family: "Avenir";
      color: rgba($color: #003361, $alpha: 0.4);
      width: 100%;
      border: none;
      background: transparent;
      outline: none;
      height: calc(100vh - 335px);
      margin: 10px 0px;
      resize: none;
      white-space: pre-line;

      &::placeholder {
        color: rgba($color: #003361, $alpha: 0.2);
      }
    }

    .error {
      padding-left: 0px !important;
    }

    @media (max-width: 600px) {
      .title {
        font-size: 24px;
        margin: 5px 0px;
      }

      .description {
        font-size: 14px;
        margin: 5px 0px;
        min-height: 120px;
      }
    }
  }

  // *Read Thought
  .read-thought {
    .title {
      font-size: 30px;
      font-family: "Avenir";
      font-weight: 900;
      color: #003361;
      width: 100%;
      margin: 10px 0px;
      word-break: break-word;
    }

    .description {
      font-size: 16px;
      font-family: "Avenir";
      color: #003361;
      line-height: 1.8;
      width: 100%;
      height: calc(100vh - 335px);
      overflow-y: auto;
      margin: 10px 0px;
      white-space: pre-line;
      word-break: break-word;
    }

    @media (max-width: 959px) {
      .title {
        font-size: 26px;
      }

      .description {
        font-size: 14px;
      }
    }

    @media (max-width: 600px) {
      .title {
        font-size: 24px;
      }

      .description {
        font-size: 12px;
      }
    }
  }

  // *Add Task & Thought Dotted Box
  .add-task,
  .add-thought {
    padding: 10px 15px;
    border: 2px dashed rgba($color: #003361, $alpha: 0.4);
    border-radius: 20px;
    text-align: center;
    cursor: pointer;

    &:hover {
      border: 2px dashed rgba($color: #003361, $alpha: 1);
      transition: 0.3s;

      svg {
        fill: #003361 !important;

        path {
          fill: #003361 !important;
        }
      }

      span {
        color: rgba($color: #003361, $alpha: 1);
      }
    }

    svg {
      vertical-align: bottom;
      margin-right: 5px;
      fill: rgba($color: #003361, $alpha: 0.2) !important;

      path {
        fill: rgba($color: #003361, $alpha: 0.2) !important;
      }
    }

    span {
      font-size: 15px;
      font-family: "Avenir";
      color: rgba($color: #003361, $alpha: 0.3);
    }

    @media (max-width: 600px) {
      span {
        font-size: 13px;
      }
    }
  }

  @media (min-width: 960px) {
    // .add-task,
    .add-thought {
      flex-grow: 0;
      max-width: 24%;
      flex-basis: 24%;
    }
  }

  @media (max-width: 959px) {
    .mission,
    .thought {
      height: 100%;
    }

    // .add-task,
    .add-thought {
      flex-grow: 0;
      max-width: 48%;
      flex-basis: 48%;
    }
  }

  @media (max-width: 600px) {
    // .add-task,
    .add-thought {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
      margin-right: 0px !important;
    }
  }

  // *Settings
  .setting {
    .heading {
      font-size: 26px;
      font-family: "Avenir";
      font-weight: 900;
      color: rgba(0, 51, 97, 0.4);
      margin-bottom: 10px;
    }

    .account,
    .app-setting,
    .user-decision {
      margin-bottom: 40px;
      .input-field {
        margin: 20px 10px;
        padding: 5px;
        width: 70%;
        border-bottom: 1px solid rgba($color: #2a2a2a, $alpha: 0.1);
        &-change-payment {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          .input-text {
            width: 100% !important;
          }
          .icon {
            height: fit-content;
            max-height: fit-content;
            overflow: hidden;
            display: flex !important;
          }
        }
        .MuiSwitch-colorSecondary.Mui-checked {
          color: #fff !important;
        }

        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
          background-color: #003361 !important;
          opacity: 1 !important;
        }

        .MuiSwitch-colorSecondary.Mui-checked:hover {
          background-color: rgba($color: #003361, $alpha: 0.04);
        }

        .icon {
          display: inline-block;

          svg {
            width: 25px;
            height: 25px;
            margin-right: 5px;
            vertical-align: super;
          }
        }

        .align-icon {
          vertical-align: middle;
          margin-top: 8px;
        }

        .input-text {
          display: inline-block;
          padding: 0px 5px;
          width: 80%;

          input {
            width: 100%;
          }

          .time-interval {
            width: 150px !important;
          }

          .MuiFormControl-root {
            width: 100%;
          }

          .MuiSelect-select {
            font-size: 16px;
            font-family: "Avenir";
            color: rgba(42, 42, 42, 0.4);
            background-color: transparent;
            padding-top: 7px;
            padding-bottom: 0px;
          }

          .MuiInput-underline:before,
          .MuiInput-underline:after {
            border: none !important;
          }

          label {
            font-size: 14px;
            font-family: "Avenir";
            font-weight: 900;
            color: rgba($color: #003361, $alpha: 0.4);
            display: block;
          }

          input {
            border: none;
            margin: 0px !important;
            background: transparent;
            color: rgba($color: #2a2a2a, $alpha: 0.4);
            font-size: 16px;
            outline: none;
            line-height: 1.5;
          }

          P {
            font-size: 18px;
            font-family: "Avenir";
            color: rgba(42, 42, 42, 0.4);

            .MuiSwitch-root {
              margin-left: 15px;
            }
          }

          @media (max-width: 600px) {
            label {
              font-size: 12px;
            }

            input {
              font-size: 14px;
            }

            P {
              font-size: 16px;

              .MuiSwitch-root {
                margin-left: 35px;
              }
            }
          }
        }
      }

      @media (max-width: 959px) {
        .input-field {
          width: 100%;
          margin: 15px 10px;
        }
      }
    }

    @media (max-width: 600px) {
      .heading {
        font-size: 24px;
        margin-bottom: 0px;
      }

      .account,
      .app-setting,
      .user-decision {
        margin-bottom: 20px;
      }
    }
  }
}

// *Forms
.form-bg {
  background-image: url(assets/images/BG.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;

  .form-wrapper {
    background-color: transparent;
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -35%);
    padding: 25px;
    text-align: center;
    width: 40%;

    .logo {
      width: 230px;
    }

    form {
      background-color: rgba($color: #fff, $alpha: 0.2);
      margin-top: 40px;
      padding: 20px;
      border-radius: 27px;

      h2 {
        font-size: 36px;
        font-family: "Rockness";
        color: #fff;
        margin: 15px 0px;
      }

      .input-field {
        display: flex;
        align-items: center;
        margin: 20px 10px;
        padding: 5px;
        border: 1px solid rgba($color: #fff, $alpha: 0.6);
        border-radius: 100px;

        .MuiListItem-root {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }

        .MuiInput-underline:before,
        .MuiInput-underline:after {
          display: none !important;
        }

        .MuiSelect-select:focus {
          background-color: transparent !important;
        }

        .MuiSelect-icon {
          display: none;
        }

        .MuiSelect-select.MuiSelect-select {
          padding: 0px !important;
        }

        .MuiSelect-selectMenu {
          height: 30px;
        }

        .icon {
          display: inline-block;
          border-radius: 100%;
          background-color: #fff;
          padding: 8px;
          text-align: center;

          svg {
            width: 14px;
            height: 14px;
          }
        }

        input {
          border: none;
          margin: 0px 5px;
          background: transparent;
          color: #fff;
          font-size: 16px;
          outline: none;
          line-height: 1.5;
          width: calc(100% - 45px);

          &::placeholder {
            color: rgba($color: #fff, $alpha: 0.7);
          }
        }
      }

      .verification-input {
        font-size: 24px;
        line-height: 1.5;
        text-align: center;
        background-color: transparent;
        color: #fff;
        outline: none;
        border: none;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.6);
        // display: inline-block;
        margin: 0px 8px 20px 8px;
        width: 30px;
      }

      p {
        font-size: 14px;
        font-family: "Avenir";
        color: rgba($color: #fff, $alpha: 0.8);
        margin-bottom: 10px;
      }

      @media (max-width: 600px) {
        h2 {
          font-size: 30px;
        }

        .input-field {
          input {
            font-size: 14px;
          }
        }

        .verification-input {
          font-size: 22px;
          margin: 0px 3px 15px 3px;
          width: 25px;
        }

        p {
          font-size: 12px;
        }
      }
    }

    p {
      font-size: 14px;
      font-family: "Avenir";
      color: rgba($color: #fff, $alpha: 0.7);

      .MuiSvgIcon-root {
        vertical-align: sub;
        font-size: 18px;
        width: 18px;
        height: 18px;
      }
    }

    @media (max-width: 959px) {
      .logo {
        width: 170px;
        height: 120px;
      }

      form {
        margin-top: 0px;
        padding: 15px;
      }
    }

    @media (max-width: 600px) {
      .logo {
        width: 140px;
        height: 120px;
      }

      form {
        margin-top: 0px;
        padding: 10px;
      }

      p {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 959px) {
    .form-wrapper {
      width: 70%;
      margin: auto;
      position: initial;
      left: 0px;
      top: 0px;
      transform: none;
    }
  }

  @media (max-width: 600px) {
    .form-wrapper {
      width: auto;
    }
  }
}

@media (max-width: 320px) {
  .form-bg {
    height: 100%;
  }
}

// *Menu Drop Down
.menu-option {
  .MuiPaper-root {
    box-shadow: none !important;
    background-color: transparent !important;
    margin-top: 10px;

    .MuiList-padding {
      padding: 0px;
    }
  }

  .option-wrapper {
    background-color: #fff;
    border-radius: 50%;
    &-action {
      display: grid;
      grid-template-columns: auto 1fr;
      border-radius: 4px !important;
      align-items: center;
      justify-items: center;
      column-gap: 6px;
      padding: 8px 6px;
      width: auto;
      cursor: pointer;
      &:hover, &:active {
        background-color: rgba(80, 125, 145, 0.2);
      }
      &:nth-child(2) {
        margin-top: 4px;
      }
    }
  }

  .edit {
    background-color: rgba($color: #7ac8c3, $alpha: 0.2);
    width: 38px;
    height: 38px;
    padding: 7px;
    display: block;

    &:hover {
      background-color: rgba($color: #7ac8c3, $alpha: 0.2) !important;
    }

    svg {
      fill: #7ac8c3 !important;

      path {
        fill: #7ac8c3 !important;
      }
    }
  }

  .deleted {
    background-color: rgba($color: #e94444, $alpha: 0.2);
    width: 38px;
    height: 38px;
    padding: 10px;
    margin-top: 5px;
    display: block;

    &:hover {
      background-color: rgba($color: #e94444, $alpha: 0.2) !important;
    }

    svg {
      fill: #e94444 !important;

      path {
        fill: #e94444 !important;
      }
    }
  }
}

// *Dialog
.MuiDialog-paper {
  margin: 0px !important;
  padding: 15px 5px !important;
  border-radius: 20px !important;
}

.MuiDialogTitle-root {
  padding: 0px !important;
  padding-top: 16px !important;
  width: 580px;

  h2 {
    font-size: 36px;
    font-family: "Rockness" !important;
    color: #6ba570;
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .MuiDialogTitle-root {
    width: 300px;
  }
}

.MuiDialogContent-root {
  .error {
    margin: 0px !important;
    padding: 0px !important;
  }

  .daily-quote,
  .author,
  .sponsor,
  .task-title {
    font-size: 18px;
    font-weight: 900;
    font-family: "Avenir";
    color: rgba($color: #003361, $alpha: 0.4);
    padding: 0px;
    margin: 0px;
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    &::placeholder {
      color: rgba($color: #003361, $alpha: 0.2);
    }
  }

  .author {
    font-size: 14px;
    margin-top: 10px;
  }

  .sponsor {
    font-size: 14px;
    margin-top: 35px;
  }

  .task-color, .list-task-color {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    width: 94%;

    .radio-container {
      display: block;
      position: relative;
      cursor: pointer;
      height: 30px;
      font-size: 22px;
      -webkit-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .radio-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: #eee;
      border-radius: 50%;
    }

    /* When the radio button is checked, add a blue background */
    .radio-container input:checked ~ .checkmark {
      background-color: #2196f3;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .radio-container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .radio-container .checkmark:after {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  

  h5 {
    font-size: 18px;
    font-weight: 900;
    font-family: "Avenir";
    color: rgba($color: #003361, $alpha: 0.4);
    width: 500px;
    text-align: center;
  }

  @media (max-width: 600px) {
    .daily-quote,
    .author,
    .sponsor,
    .task-title {
      font-size: 16px;
    }

    .task-color {
      .radio-container {
        height: 25px;
        font-size: 20px;
      }

      /* Create a custom radio button */
      .checkmark {
        height: 24px;
        width: 24px;
      }

      /* Style the indicator (dot/circle) */
      .radio-container .checkmark:after {
        width: 14px;
        height: 14px;
      }
    }

    h5 {
      font-size: 16px;
      width: 250px;
    }
  }
}

.list-task-color {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  width: 94%;

  .radio-container {
    display: block;
    position: relative;
    cursor: pointer;
    height: 30px;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #eee;
    border-radius: 50%;
  }

  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radio-container .checkmark:after {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// *For Stripe
.payment-select-box{
  border: 1px solid hsla(0, 0%, 100%, .6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 23px;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  -ms-border-radius: 23px;
  -o-border-radius: 23px;

  &:hover{
  background-color: rgba($color: #fff, $alpha: 0.5);
  p {
    color: #003361 !important;
    }
  }

  p {
      margin: 0px !important;
    }
  
    .price {
      font-size: 20px !important;
      font-weight: 700;
    }
  .MuiRadio-root {
    color: rgba($color: #fff, $alpha: 0.5);
  }    
}

.selected-payment-box  {
  background-color: rgba($color: #fff, $alpha: 0.5);
  p {
    color: #003361 !important;
  }
  .MuiRadio-root {
    color: #003361;
  }
}

.selected-payment-box-disabled{
  opacity: 0.5;
  cursor: inherit;
  &:hover {
      background-color: transparent !important;
  
      p {
        color: #fff !important;
      }
    }
}

// mission
.mission-header {
  border-radius: 0px !important;
  padding: 10px;
  &-container {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 5px;
  }
  &-icon-more {
    background-color: transparent !important;
    width: 24px !important;
    height: 24px !important;
    padding: 0 !important;
    margin-right: 4px !important;
  }
}

.task-color-edit-container {
  * {
    box-sizing: border-box !important;
  }
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  border: 1px solid rgba(194, 205, 217, 1);
  padding: 6px 10px 10px 10px;
  display: grid;
  grid-template-rows: auto auto;
  row-gap: 6px;
  .task-color-title {
    color: rgba(132, 135, 138, 1);
    font-size: 12px;
    font-weight: 800;
    line-height: 16.39px;
  }
  .list-task-color {
    margin-top: 0;
    .checkmark {
      width: 14px;
      height: 14px;
      &::after {
        width: 7.5px;
        height: 7.5px;
      }
    }
    .radio-container {
      height: 14px;
    }
  }
}

#MainContent .mission .edit-task-form {
  margin-bottom: 12px !important;
  .task-box {
    margin-bottom: 8px !important;
  }
}

.paper-dialog-delete-task {
  * {
    box-sizing: border-box;
  }
  padding: 24px 34px 30px 34px !important;
  .MuiDialogContent-root {
    overflow: hidden !important;
    padding: 0 !important;
  }
  .MuiDialogActions-root {
    padding: 0 !important;
    margin-top: 31px;
  }
}

.dialog-delete-list-content {
  display: grid;
  grid-template-rows: auto auto auto;
  justify-items: center;
  align-items: center;
  .dialog-delete-list-title {
    font-size: 18px !important;
    font-weight: 900;
    color: rgba(17, 51, 94, 1) !important;
    text-align: center;
    height: 25px;
    margin-top: 16px;
    margin-bottom: 1px;
  }
  .dialog-delete-list-sub {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    height: 32px;
    color: rgba(132, 135, 138, 1);
  }
}

// sub task

.sub-task-main {
  z-index: 99;
  position: relative;
 
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}

.sub-task-form-home {
  width: 100%;
  grid-template-columns: 1fr !important;
  box-sizing: border-box !important;
}

.sub-task-form {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  
  * {
    box-sizing: border-box !important;
  }
  .sub-task-input, .sub-task-display {
    border-radius: 0px !important;
    border: none !important;
    padding: 0 !important;
    color: rgba(17, 51, 94, 1) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    position: relative;
    background-color: transparent;
    z-index: 1;
    resize: none;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 999;
    }
    &-complete {
      text-decoration: line-through
    }
  }
  .sub-task-display {
    width: 100%;
  }
  .sub-task-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: hidden;
    border: 1px solid rgba(194, 205, 217, 1);
    padding: 8px 12px !important;
    border-radius: 10px !important;
    margin-left: -16px;
    width: calc(100% + 16px);
    transition: all 0.2s;
    &:hover {
      background-color: rgba(80, 125, 145, 0.1)
    }
  }
  .sub-task-delete {
    transform: translateX(100%);
    transition: all 0.2s;
    visibility: hidden;
    margin-left: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: rgba(80, 125, 145, 0.27);
    }
  }
  .sub-task-reminder {
    margin-right: 16px;
    transform: translateX(100%);
    transition: all 0.2s;
    border-radius: 4px;
    cursor: pointer;
    &-overdue {

    }
    &:hover {
      background-color: rgba(80, 125, 145, 0.27);
    }
  }
  .sub-task-drag {
    visibility: hidden;
  }
  &:hover {
    .sub-task-delete {
      transform: translateX(0%);
      visibility: visible;
    }
    .sub-task-reminder {
      transform: translateX(0%);
    }
    .sub-task-container {
      margin-left: 0;
      width: 100%;
    }
    .sub-task-drag {
      width: 100% !important;
      visibility: visible;
    }
  }
}

// tool tip
.tooltip-delete {
  z-index: 2000 !important;
  top: -12px !important;
  .MuiTooltip-tooltipArrow {
    background-color: rgba(17, 51, 94, 1) !important;
    color: white;
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    .MuiTooltip-arrow {
      color: rgba(17, 51, 94, 1) !important;
    }
  }
  .MuiTooltip-tooltip {
    margin: 0;
    background-color: transparent;
    padding: 9px 13px;
  }
}

// check box

.checkbox-subtask {
  padding: 0 !important;
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    path {
      width: 16px;
      height: 16px;
      font-size: 16px;
    }
  }
}

.checkbox-round-task {
  display: flex;
  align-items: center;
  margin-right: 7px;
}

.content-sub-tasks {
  padding: 8px !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
  * {
    box-sizing: border-box !important;
  }
}

.menu-date-picker-list {
  padding: 0 !important;
}

#menu-date-picker {
  z-index: 2100 !important;
  * {
    box-sizing: border-box !important;
    font-family: "Avenir" !important;
  }
  .menu-date-picker-wrapper {
    padding: 16px;
    .header-date {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: 1fr;
      column-gap: 6px;
      .header-date--year {
        grid-column: 2 / 3;
        grid-row: 1/2;
        margin: 0;
      }
      .header-date--month {
        grid-column: 1 / 2;
        grid-row: 1/2;
        margin: 0;
      }
    }
  }
  .menu-date-picker-header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }
  .menu-date-picker-title {
    font-size: 16px;
    font-weight: 900;
    color: rgba(17, 51, 94, 1);
  }
  .calender-modal {
    display: block !important;
    width: 100% !important;
    box-shadow: unset;
    .daysList {
      width: 100% !important;
      display: grid !important;
      grid-template-columns: repeat(7, 1fr) !important;
    }
    .header {
      background-color: transparent;
      padding: 0;
      margin: 13px 0;
      border: none !important;
      .header-date {
        color: rgba(2, 2, 2, 1) !important;
        font-size: 16px;
        font-weight: 700;
      }
      .header--btn  {
        path {
          color: rgba(2, 2, 2, 1) !important;
        }
      }
    }
    .daysList {
      padding: 0 0px 6px 0px !important;
      padding-top: 0 !important;
      border-bottom: solid 1px rgba(217, 223, 226, 1);
      margin-bottom: 7px;
      .daysList-day {
        width: 36px !important;
        height: 36px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1 !important;
        text-align: center;
      }
      .daysList-day.is-week-days {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: rgba(213, 213, 214, 1) !important;
      }
      .daysList-day.is-disabled {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: rgba(213, 213, 214, 1) !important;
      }
      .daysList-day.is-pointer {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: rgba(106, 106, 109, 1) !important;
      }
      .daysList-day.is-pointer {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: rgba(106, 106, 109, 1);
      }
      .daysList-day.is-selected-day {
        background-color: rgba(80, 125, 145, 1);
        color: rgba(255, 255, 255, 1) !important;
      }
      .is-today {
        border-color: rgba(80, 125, 145, 1) !important;
        box-shadow: inset 0 0 0 1.5px rgba(80, 125, 145, 1);
      }
      .is-disabled {
        background-color: transparent;
      }
    }
    .time {
      position: relative;
      display: grid;
      grid-template-rows: auto 1fr;
      font-size: 56px;
      margin: 0;
      justify-items: flex-start !important;
      justify-content: unset !important;
      padding: 0 16px;
      padding-bottom: 9px;
      .field-wrapper {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        height: fit-content;
        column-gap: 10px;
      }
      .time--title {
        display: none;
      }
      &::before {
        content: 'Select time';
        font-size: 12px;
        font-weight: 500;
        color: rgba(113, 115, 112, 1);
        height: 20px;
        margin-bottom: 4px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
      }
      .time-fieldset  {
        width: 70px;
        height: 48px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 6px 0;
        &::after {
          position: absolute;
          top: 100%;
          font-size: 12px;
          font-weight: 400;
          color: rgba(141, 137, 156, 1);
          left: 0;
          margin-top: 6px;
        }
        .time--input {
          font-size: 24px;
          font-weight: 400;
          color: rgba(19, 53, 95, 1);
          width: 100%;
          background-color: transparent;
        }
      }
    }
  }
  .menu-date-picker-btn {
    width: 100%;
    background-color: rgba(80, 125, 145, 1);
    height: 33px;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 15px;
    font-weight: bold !important;
    font-family: 'Avenir';
    margin-top: 20px !important;
    text-transform: uppercase;
  }
}

.dropdown-notification {
  * {
    font-family: 'Avenir' !important;
    box-sizing: border-box !important;
  }
  .MuiList-root {
    padding: 0 !important;
  }
  .notify-wrapper {
    .MuiListItem-root {
      border-color: rgba(217, 223, 226, 1) !important;
    }
  }
  .notify-header {
    display: grid;
    grid-template-columns: auto 1fr;
    width: fit-content;
    padding: 24px 25px 17px 25px;
    column-gap: 8px;
    border-bottom: solid 1px rgba(217, 223, 226, 1);
    width: 100%;
    justify-items: flex-start;
    &-title {
      font-size: 18px;
      font-weight: 900;
      color: rgba(17, 51, 94, 1);
    }
    &-image {
      height: 24px !important;
    }
  }
  .noti-item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
    align-items: center;
    column-gap: 13px;
    max-width: 100%;
    overflow: hidden;
    padding: 9px 12px 9px 18px !important;
    &-readed{
      background-color: rgba(149, 166, 186, 0.17);
    }
    &-status {
      height: 24px;
    }
    &-container {
      display: grid;
      grid-template-rows: 1fr 1fr;
    }
    &-title {
      font-size: 14px;
      font-weight: 500;
      color: rgba(80, 125, 145, 1);
    }
    &-sub {
      font-size: 16px;
      font-weight: 900;
      color: rgba(17, 51, 94, 1);
    }
    &-time {
      font-size: 14px;
      font-weight: 500;
      color: rgba(151, 151, 151, 1);
    }
  }
}

.modal-reminder-detail {
  display: grid;
  align-items: center;
  justify-items: center;
  .modal-reminder-container {
    background-color: white;
    padding: 0 !important;
    border-radius: 29px;
    border: none;
    outline: none !important;
    padding: 0 70px 35px 70px !important;
    min-width: 376px;
  }
  .modal-reminder-content {
    
  }
  .modal-reminder-content-nodata {
    display: grid;
    align-items: center;
    justify-items: center;
  }
  .modal-reminder-nodata {
    font-size: 18px;
    font-weight: 800;
    color: rgba(151, 151, 151, 1);
    height: 100px;
    display: grid;
    align-items: center;
    justify-items: center;
  }
}
.task-box-info {
  border-radius: 17px !important;
  overflow: hidden;
  border: solid 1px;
}

.header-mission-container {
  .menu-option {
    display: none !important;
    overflow: hidden;
  }
}

.sub-task-form-main {
  padding: 8px 8px 10px 8px;
  .sub-task-container {
    width: 100% !important;
    margin-left: 0;
  }
  .sub-task-reminder {
    transform: translateX(0) !important;
  }
}

.modal-reminder-header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 20px -46px;
  &-title {
    font-size: 18px;
    font-weight: 900;
    color: rgba(17, 51, 94, 1);
  }
}

.modal-reminder-footer-main {
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  .modal-reminder-footer {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: 19px;
    height: 24px;
    width: fit-content;
    column-gap: 9px;
    &-date {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 6px;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: rgba(132, 135, 138, 1);
    }
    &-line {
      width: 1px;
      height: 24px;
      background-color: rgba(194, 205, 217, 1);
    }
  }
}

.tooltip-overdue {
  background-color: white;
  z-index: 888 !important;
  padding: 0 !important;
  pointer-events: unset !important;
  border-radius: 24px;
  border: solid 1px rgba(213, 213, 215, 1);
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.24);
  .MuiTooltip-tooltip {
    margin: 0;
    background-color: transparent;
    padding: 9px 14px;
  }
  * {
    box-sizing: border-box;
  }
  &-contaier {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    z-index: 10000 !important;
    column-gap: 11px;
  }
  &-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    column-gap: 8px;
  }
  &-item {
    font-size: 13px;
    font-weight: 400;
    color: rgba(236, 77, 78, 1) !important;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    column-gap: 2px;
  }
  &-line {
    height: 100%;
    width: 1px;
    background-color: rgba(194, 205, 217, 1);
  }
}

.tooltip-remindered {
  .tooltip-overdue-item {
    color: rgba(132, 135, 138, 1) !important;
    line-height: 1 !important;
  }
}

.tooltip-overdue-item {
  line-height: 1 !important;
}

.tooltip-remindered {
  // margin: 0 !important;
  pointer-events: inherit !important;
  .MuiTooltip-tooltip {
    background-color: rgba(255, 255, 255, 1) !important;
    box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.24);
    border: 1px solid var(--Stroke, rgba(213, 213, 215, 1));
    border-radius: 24px;
    pointer-events: inherit !important;
  }
}

.menu-date-picker-action-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 13px;
  * {
    box-sizing: border-box;
    font-family: 'Avenir';
  }
  .menu-date-picker-btn-edit {
    font-size: 15px;
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    border-radius: 50px !important;
    height: 33px !important;
    font-family: 'Avenir';
    &:nth-child(1) {
      background-color: white !important;
      border: 1px solid rgba(17, 51, 94, 1) !important;
      color: rgba(17, 51, 94, 1) !important;
    }
  }
}

.select-time-reminder-title {
  font-size: 10px;
  font-weight: 500;
  color: rgba(113, 115, 112, 1);
  line-height: 16px;
  margin-bottom: 6px;
  margin-top: 5px;
  text-transform: uppercase !important;
  letter-spacing: 1.5px;
}

.select-time-reminder-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 12px;
  align-items: flex-start;
  * {
    box-sizing: border-box;
    font-family: 'Avenir' !important;
  }
  box-sizing: border-box;
  .select-time-reminder-group {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 10px;
    width: fit-content;
    align-items: center;
    height: fit-content;
  }
  .select-time-reminder {
    width: 75px;
    height: 48px;
    font-size: 24px;
    font-weight: 400;
    font-family: 'Avenir';
    color: rgba(19, 53, 95, 1);
    text-align: center;
    border: none;
    outline: none;
    background-color: rgba(217, 217, 217, 0.47) !important;
    border-radius: 4px;
    padding: 0 !important;
    padding-left: 12px !important;
    &:hover {
      background-color: rgba(136, 195, 192, 0.25) !important;
    }
  }
  .select-time-reminder-dot {
    font-size: 56px;
    font-weight: 400;
    line-height: 1 !important;
    margin-top: -30px;
  }
  .select-time-reminder-action-group {
    display: grid;
    grid-template-columns: auto auto;
    border: 1px solid rgba(218, 220, 224, 1);
    border-radius: 4px !important;
    width: fit-content;
    overflow: hidden;
    * {
      box-sizing: border-box;
      font-family: 'Avenir';
    }
  }
  .select-time-reminder-btn {
    width: 45px;
    height: 48px;
    outline: none;
    border: none !important;
    background-color: white !important;
    cursor: pointer;
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.6);
    &:hover {
      background-color: rgba(136, 195, 192, 0.25) !important;
    }
    &-selected {
      background-color: rgba(136, 195, 192, 0.25) !important;
      color: rgba(19, 53, 95, 1);
    }
  }
  .select-time-reminder-item {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 8px;
    p {
      font-size: 12px;
      font-weight: 400;
      color: rgba(141, 137, 156, 1);
      margin: 0 !important;
    }
  }
}

.btn-tooltip-edit-reminder {
  &:hover {
    path {
      color: rgba(17, 51, 94, 1) !important;
      stroke: rgba(17, 51, 94, 1) !important;
    }
  }
} 

.paper-dialog-payment {
  max-width: unset !important;
  width: fit-content !important;
  min-width: 280px !important;
  .MuiDialogContent-root {
    min-width: 100% !important;
    width: fit-content !important;
    overflow: hidden !important;
    padding: 0 !important;
  }
  .dialog-delete-list-content {
    height: 49px;
    position: relative;
    display: grid;
    align-items: flex-end;
    background-color: transparent;
    margin-bottom: 8px;
    .dialog-payment-close-icon {
      position: absolute;
      bottom: 25px;
      right: 0;
    }
    .dialog-payment-title {
      margin-top: 0;
      text-align: left;
      width: 100%;
    }
  }
  .btn-save-payment {
    margin: 0 !important;
    margin-top: 20px !important;
    padding: 0;
    height: 45px;
    width: 100% !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase;
  }
}

.payment-info {
  * {
    box-sizing: border-box;
    font-family: 'Avenir' !important;
  }
  &-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;

  }
  &-btn {
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: 700;
    color: rgba(149, 166, 185, 1);
    text-transform: uppercase;
    cursor: pointer;
  }
}

.form-setting-payment-current {
  * {
    box-sizing: border-box !important;
    font-family: 'Avenir' !important;
  }
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
  height: fit-content;
  margin-bottom: 14px;
  &-title {
    font-size: 14px;
    font-weight: 700;
    color: rgba(132, 135, 138, 1);
  }
  &-info {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    padding: 8px 14px;
    background-color: rgba(237, 244, 247, 1);
    border-radius: 11px;
  }
  &-brand, &-number {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: rgba(159, 163, 165, 1);
  }
  &-brand {
    text-transform: capitalize;
  }
  &-new {
    font-size: 14px;
    font-weight: 800 !important;
    color: rgba(132, 135, 138, 1) !important;
    margin-bottom: 14px;
  }
}

.dialog-payment-header {
  grid-template-rows: unset !important;
  height: 37px !important;
  img {
    bottom: 18px !important;
  }
}

.paper-dialog-payment-loading {
  display: grid;
  align-items: center;
  justify-items: center;
  row-gap: 16px;
  &-title {
    color: rgba(17, 51, 94, 1);
    font-size: 18px;
    font-weight: 800;
  }
}

.form-setting-payment-first-loading {
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 20px;
}

.payment-info-method-current {
  display: grid;
  grid-template-columns: auto auto;
  width: fit-content;
  color: rgba(159, 163, 165, 1);
  font-size: 16px;
  font-weight: 500;
  .payment-info-method-brand {
    text-transform: capitalize;
  }
}

// Payment
.payment-dialog {
  max-width: 743px !important;
  padding: 0 !important;

  .MuiDialogContent-root {
    overflow: hidden !important;
    padding: 33px !important;
  }

  .subscription {
    .logo {
      width: 47px;
      height: 28px;
    }
  }
}

.add-payment-dialog {
  width: 410px !important;
  padding: 0 !important;

  .MuiDialogContent-root {
    padding: 30px !important;
  }
}

// CARD
.card {
  background-color: #EDF4F7;
  border-radius: 11px;
  padding: 16px 22px;
}

// TEXT
.text-primary {
  color: #11335E;
}

.text-second {
  color: #84878A;
}