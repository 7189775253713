// dialog add task
.container-dialog-add-task {
  * {
    box-sizing: border-box !important;
    font-family: 'Avenir' !important;
  }
}

.MuiPaper-root.paper-dialog-add-task {
  padding: 35px 30px !important;
  overflow: hidden;
  width: 520px;
  .title-dialog-add-task {
    color: #11335E !important;
    font-size: 18px;
    font-weight: 900;
    text-align: left;
    h2 {
      font-family: 'Avenir' !important;
    }
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .task-color {
    width: 100%;
    margin-top: 0;
    gap: 11px;
    justify-content: unset;
    .radio-container {
      width: 24px;
    }
  }
  .task-color-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 8px;
    margin-top: 26px;
  }
  .task-color-title {
    color: #84878A;
    font-weight: 800;
    font-size: 14px;
  }
  .MuiDialogTitle-root {
    padding-top: 0 !important;
    margin-bottom: 25px;
  }
  .MuiDialogActions-root {
    padding: 0;
    height: fit-content !important;
    margin-top: 45px;
  }
}

.MuiPaper-root.paper-dialog-add-task, .MuiPaper-root.paper-dialog-delete-task {
  .dialog-button-stroke {
    border-color: #11335E;
    color: #173A62 !important;
  }
  .btn-action {
    margin: 0 !important;
    padding: 0;
    height: 45px;
    width: 100% !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    letter-spacing: 1px !important;
  }
  .MuiGrid-container {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    column-gap: 11px;
    .MuiGrid-item {
      width: 100% !important;
      max-width: unset !important;
    }
  }
}


@media (min-width: 376px) and (max-width: 600px) {
  .MuiPaper-root.paper-dialog-add-task {
    width: calc(100% - 40px);
    padding: 25px 20px !important;
  }
}

.MuiDialog-paperWidthSm {
  max-width: 600px;
  min-width: 376px;
}

